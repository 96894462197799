import { FetchMyCompanyResponse } from "./types";
import { axiosInstanceWithoutInterceptors } from "../axiosInstance";

export const fetchMyCompany = async (
  token: string,
): Promise<FetchMyCompanyResponse> => {
  const response =
    await axiosInstanceWithoutInterceptors.get<FetchMyCompanyResponse>(
      "/api/private/my-company",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  return response.data;
};
