import React, { useState } from "react";
import SkinnySidebar from "../SkinnySidebar/SkinnySidebar";
import WideSidebar from "../WideSidebar/WideSidebar";
import TopNavBar from "../TopNavBar/TopNavBar";
import MainContent from "../MainContent/MainContent";
import MobileSidebarDialog from "../MobileSidebarDialog/MobileSidebarDialog";
import { Outlet, useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Footer from "../Footer/Footer";
import { getNavigation } from "../../utils/navigationConfig/navigationConfig";

interface CombinedLayoutProps {
  /*children: React.ReactNode;*/
  section: string | null;
}

const MainLayout: React.FC<CombinedLayoutProps> = ({ section }) => {
  const [sidebarOpenWide, setSidebarOpenWide] = useState(false);
  const location = useLocation();

  const navigationWide = getNavigation(section, location.pathname);

  return (
    <div className="flex h-screen">
      <SkinnySidebar />
      <WideSidebar navigation={navigationWide} section={section} />
      <div className="flex flex-1 flex-col">
        <TopNavBar onSidebarToggle={() => setSidebarOpenWide(true)} />
        <Breadcrumbs />
        <MainContent>
          <Outlet />
        </MainContent>
        <Footer />
      </div>
      <MobileSidebarDialog
        navigationWide={navigationWide}
        section={section}
        setSidebarOpen={setSidebarOpenWide}
        sidebarOpen={sidebarOpenWide}
      />
    </div>
  );
};

export default MainLayout;
