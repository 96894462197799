import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FetchProspectsRequest,
  FetchProspectsResponse,
  Prospect,
} from "../../../../../api/fetchProspects/types";
import { AppDispatch, AppThunk } from "../../../../../app/store";
import { fetchProspects } from "../../../../../api/fetchProspects/fetchProspectsApi";

interface ProspectsState {
  prospects: Prospect[];
  totalCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: ProspectsState = {
  prospects: [],
  totalCount: 0,
  loading: false,
  error: null,
};

const prospectsSlice = createSlice({
  name: "prospects",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setProspectsData: (
      state,
      action: PayloadAction<{ prospects: Prospect[]; totalCount: number }>,
    ) => {
      state.prospects = action.payload.prospects;
      state.totalCount = action.payload.totalCount;
    },
  },
});

export const { setLoading, setError, setProspectsData } =
  prospectsSlice.actions;

export const fetchProspectsAction =
  (requestData: FetchProspectsRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: FetchProspectsResponse =
        await fetchProspects(requestData);
      dispatch(
        setProspectsData({
          prospects: response.data.prospects,
          totalCount: response.meta?.totalCount || 0,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Failed to fetch prospects"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export default prospectsSlice.reducer;
