import axios, { AxiosInstance } from "axios";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js/dist/typings/global";
import { fetchMyCompany } from "./fetchMyCompany/fetchMyCompanyApi";

// Original axios instance with interceptors
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// New axios instance without interceptors
export const axiosInstanceWithoutInterceptors: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add access token interceptor
export const addAccessTokenInterceptor = (
  axiosInstance: AxiosInstance,
  getAccessTokenSilently: {
    (
      options: GetTokenSilentlyOptions & { detailedResponse: true },
    ): Promise<GetTokenSilentlyVerboseResponse>;
    (options?: GetTokenSilentlyOptions): Promise<string>;
    (
      options: GetTokenSilentlyOptions,
    ): Promise<GetTokenSilentlyVerboseResponse | string>;
  },
  loginWithRedirect: () => Promise<void>,
) => {
  // Request interceptor to add the access token to requests
  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          },
        });
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Response interceptor to handle 401 errors and redirect to login
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        console.log("Unauthorized! Redirecting to login...");
        await loginWithRedirect();
      }
      return Promise.reject(error);
    },
  );
};

// Add company UUID interceptor
export const addCompanyUuidInterceptor = (
  axiosInstance: AxiosInstance,
  getAccessTokenSilently: {
    (
      options: GetTokenSilentlyOptions & { detailedResponse: true },
    ): Promise<GetTokenSilentlyVerboseResponse>;
    (options?: GetTokenSilentlyOptions): Promise<string>;
    (
      options: GetTokenSilentlyOptions,
    ): Promise<GetTokenSilentlyVerboseResponse | string>;
  },
) => {
  const fetchCompanyUuid = async () => {
    try {
      // Get the access token
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });

      const myCompanyResponse = await fetchMyCompany(token);
      const companyUuid = myCompanyResponse.data.uuid;

      if (companyUuid) {
        localStorage.setItem("selectedCompanyUuid", companyUuid);
        return companyUuid;
      } else {
        console.error("No company UUID found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching company UUID:", error);
      return null;
    }
  };

  axiosInstance.interceptors.request.use(
    async (config) => {
      let companyUuid = localStorage.getItem("selectedCompanyUuid");

      if (!companyUuid) {
        companyUuid = await fetchCompanyUuid();
      }

      if (companyUuid) {
        config.headers["X-Company-UUID"] = companyUuid;
      } else {
        console.error("Company UUID is missing and could not be retrieved.");
      }

      if (process.env.REACT_APP_API_DEBUG === "true") {
        const separator = config.url?.includes("?") ? "&" : "?";
        config.url += `${separator}XDEBUG_SESSION_START=PHPSTORM`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

// Export the axios instances
export { axiosInstance };
export default axiosInstance;
