import React from "react";
import { Route } from "react-router-dom";
import HubDashboard from "../../modules/hub/features/HubDashboard/HubDashboard";
import MonthlyBalanceSheet from "../../modules/hub/features/DocumentLibrary/components/MonthlyBalanceSheet/MonthlyBalanceSheet";
import ProfitAndLoss from "../../modules/hub/features/DocumentLibrary/components/ProfitAndLoss/ProfitAndLoss";
import TransactionList from "../../modules/hub/features/DocumentLibrary/components/TransactionList/TransactionList";
import DataConnector from "../../modules/hub/features/HotglueDataConnector/components/DataConnector/DataConnector";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";
import UserList from "../../modules/hub/features/UserManagement/components/UserList/UserList";
import EditUser from "../../modules/hub/features/UserManagement/components/EditUser/EditUser";
import CreateUser from "../../modules/hub/features/UserManagement/components/CreateUser/CreateUser";
import FieldLaborDashboard from "../../modules/hub/features/FieldLaborDashboard/FieldLaborDashboard";

const HubRoutes = (
  <Route
    element={
      <AuthenticationGuard component={() => <MainLayout section="hub" />} />
    }
    path="/hub"
  >
    <Route element={<HubDashboard />} index />
    <Route element={<FieldLaborDashboard />} path="dashboards/field-labor" />
    <Route
      element={<MonthlyBalanceSheet />}
      path="document-library/monthly-balance-sheet"
    />
    <Route
      element={<ProfitAndLoss />}
      path="document-library/profit-and-loss"
    />
    <Route
      element={<TransactionList />}
      path="document-library/transaction-list"
    />
    <Route element={<DataConnector />} path="data-connector" />
    <Route element={<UserList />} path="users" />
    <Route element={<EditUser />} path="users/:uuid/edit" />
    <Route element={<CreateUser />} path="users/create" />
  </Route>
);

export default HubRoutes;
