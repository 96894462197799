import React from "react";
import { Route } from "react-router-dom";
import CustomerList from "../../modules/stochastic/features/CustomerList/components/CustomerList/CustomerList";
import ProspectList from "../../modules/stochastic/features/ProspectList/components/ProspectList/ProspectList";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";

const StochasticRoutes = (
  <Route
    element={
      <AuthenticationGuard
        component={() => <MainLayout section="stochastic" />}
      />
    }
    path="/stochastic"
  >
    <Route element={<ProspectList />} index />
    <Route element={<CustomerList />} path="customers" />
    <Route element={<ProspectList />} path="prospects" />
  </Route>
);

export default StochasticRoutes;
