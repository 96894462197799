import React, { useMemo } from "react";
import { useProspects } from "../../hooks/useProspects";
import { createProspectsColumns } from "../ProspectsColumns/ProspectsColumns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import ProspectListFilters from "../ProspectListFilters/ProspectListFilters";

const ProspectList: React.FC = () => {
  const {
    prospects,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
    filters,
  } = useProspects();

  const columns = useMemo(() => createProspectsColumns(), []);

  return (
    <MainPageWrapper error={error} loading={loading} title="Prospects">
      <ProspectListFilters
        filters={filters}
        onFilterChange={(fullName: string) => {
          handleFilterChange(fullName);
          handlePaginationChange({
            pageIndex: 0,
            pageSize: pagination.pageSize,
          });
        }}
      />

      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={prospects}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default ProspectList;
