import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Customer,
  FetchCustomersRequest,
  FetchCustomersResponse,
} from "../../../../../api/fetchCustomers/types";
import { AppDispatch, AppThunk } from "../../../../../app/store";
import { fetchCustomers } from "../../../../../api/fetchCustomers/fetchCustomersApi";

interface CustomersState {
  customers: Customer[];
  totalCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: CustomersState = {
  customers: [],
  totalCount: 0,
  loading: false,
  error: null,
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setCustomersData: (
      state,
      action: PayloadAction<{ customers: Customer[]; totalCount: number }>,
    ) => {
      state.customers = action.payload.customers;
      state.totalCount = action.payload.totalCount;
    },
  },
});

export const { setLoading, setError, setCustomersData } =
  customersSlice.actions;

export const fetchCustomersAction =
  (requestData: FetchCustomersRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: FetchCustomersResponse =
        await fetchCustomers(requestData);
      dispatch(
        setCustomersData({
          customers: response.data.customers,
          totalCount: response.meta?.totalCount || 0,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Failed to fetch customers"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export default customersSlice.reducer;
