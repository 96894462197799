import React, { useEffect } from "react";
import "./App.css";
import AppRoutes from "./app/AppRoutes";
import useSessionRewind from "./hooks/useSessionRewind";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addAccessTokenInterceptor,
  addCompanyUuidInterceptor,
} from "./api/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserAppSettingsAction } from "./modules/hub/features/UserAppSettings/slices/userAppSettingsSlice";
import { RootState } from "./app/rootReducer";
import { ThemeProvider } from "./context/ThemeContext";
import { NotificationProvider } from "./context/NotificationContext";
import axiosInstance from "./api/axiosInstance";

function App() {
  useSessionRewind();
  const dispatch = useDispatch();

  const {
    isLoading: authLoading,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const userAppSettingsLoading = useSelector(
    (state: RootState) => state.userAppSettings.loading,
  );

  useEffect(() => {
    addAccessTokenInterceptor(
      axiosInstance,
      getAccessTokenSilently,
      loginWithRedirect,
    );

    addCompanyUuidInterceptor(axiosInstance, getAccessTokenSilently);
  }, [getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserAppSettingsAction());
    }
  }, [isAuthenticated, dispatch]);

  if (authLoading || userAppSettingsLoading) {
    return null;
  }

  return (
    <ThemeProvider>
      <NotificationProvider>
        <AppRoutes />
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
