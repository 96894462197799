import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { Prospect } from "../../../../../../api/fetchProspects/types";

export function createProspectsColumns(): ColumnDef<Prospect>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Full Name",
      accessorKey: "full_name",
    },
    {
      header: "Customer ID",
      accessorKey: "customer_id",
      cell: ({ row }) =>
        row.original.customer_id ? row.original.customer_id : "N/A",
    },
    /*    {
      header: "External ID",
      accessorKey: "external_id",
    },*/
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div>
          {/* Add action buttons or links here */}
          <button onClick={() => alert(`Prospect ID: ${row.original.id}`)}>
            View
          </button>
        </div>
      ),
      enableSorting: false,
    },
  ];
}
