import { UsersIcon, EyeIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const stochasticNavigation: NavigationItem[] = [
  {
    name: "Prospects",
    href: "/stochastic/prospects",
    icon: EyeIcon,
    current: false,
    internalName: "prospects",
    permissions: ["CAN_MANAGE_PROSPECTS"],
  },
  {
    name: "Customers",
    href: "/stochastic/customers",
    icon: UsersIcon,
    current: false,
    internalName: "customers",
    permissions: ["CAN_MANAGE_CUSTOMERS"],
  },
];

export default stochasticNavigation;
