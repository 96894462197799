import { combineReducers } from "@reduxjs/toolkit";
import customersReducer from "../modules/stochastic/features/CustomerList/slices/customersSlice";
import prospectsReducer from "../modules/stochastic/features/ProspectList/slices/prospectsSlice";
import userAppSettingsReducer from "../modules/hub/features/UserAppSettings/slices/userAppSettingsSlice";
import quickBooksReportsReducer from "../modules/hub/features/DocumentLibrary/slices/quickBooksReportsSlice";
import usersReducer from "../modules/hub/features/UserManagement/slices/usersSlice";
import editUserDetailsReducer from "../modules/hub/features/UserManagement/slices/editUserDetailsSlice";

const rootReducer = combineReducers({
  customers: customersReducer,
  prospects: prospectsReducer,
  userAppSettings: userAppSettingsReducer,
  quickBooksReports: quickBooksReportsReducer,
  users: usersReducer,
  editUserDetails: editUserDetailsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
