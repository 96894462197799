import React, { useMemo } from "react";
import { useCustomers } from "../../hooks/useCustomers";
import { createCustomersColumns } from "../CustomersColumns/CustomersColumns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import CustomerListFilters from "../CustomerListFilters/CustomerListFilters";

const CustomerList: React.FC = () => {
  const {
    customers,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
    filters,
  } = useCustomers();

  const columns = useMemo(() => createCustomersColumns(), []);

  return (
    <MainPageWrapper error={error} loading={loading} title="Customers">
      <CustomerListFilters
        filters={filters}
        onFilterChange={(name: string) => {
          handleFilterChange(name);
          handlePaginationChange({
            pageIndex: 0,
            pageSize: pagination.pageSize,
          });
        }}
      />

      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={customers}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default CustomerList;
