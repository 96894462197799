import { FetchCustomersRequest, FetchCustomersResponse } from "./types";
import axios from "../axiosInstance";

export const fetchCustomers = async (
  requestData: FetchCustomersRequest,
): Promise<FetchCustomersResponse> => {
  const response = await axios.get<FetchCustomersResponse>(
    "/api/private/stochastic-customers",
    { params: requestData },
  );
  return response.data;
};
