import React from "react";
import { Table as ReactTableInstance } from "@tanstack/react-table";

interface DataTablePaginationProps<TData extends object> {
  table: ReactTableInstance<TData>;
}

function DataTablePagination<TData extends object>({
  table,
}: DataTablePaginationProps<TData>) {
  const renderPaginationButtons = () => {
    const totalPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex;
    const pageNumbers = [];

    for (let i = 0; i < totalPages; i++) {
      if (i === currentPage) {
        pageNumbers.push(
          <button
            className="px-4 py-2 bg-secondary dark:bg-primary text-white rounded-md focus:outline-none"
            key={i}
            onClick={() => table.setPageIndex(i)}
          >
            {i + 1}
          </button>,
        );
      } else if (i < 3 || i === totalPages - 1) {
        pageNumbers.push(
          <button
            className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark focus:outline-none"
            key={i}
            onClick={() => table.setPageIndex(i)}
          >
            {i + 1}
          </button>,
        );
      } else if (i === 3) {
        pageNumbers.push(<span key="ellipsis">...</span>);
      }
    }

    return pageNumbers;
  };

  return (
    <div className="mt-4 flex justify-between items-center">
      <button
        className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark disabled:bg-gray-300 dark:bg-primary dark:hover:bg-primary-dark focus:outline-none"
        disabled={!table.getCanPreviousPage()}
        onClick={() => table.previousPage()}
      >
        Previous
      </button>
      <div className="flex space-x-2">{renderPaginationButtons()}</div>
      <button
        className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark disabled:bg-gray-300 dark:bg-primary dark:hover:bg-primary-dark focus:outline-none"
        disabled={!table.getCanNextPage()}
        onClick={() => table.nextPage()}
      >
        Next
      </button>
    </div>
  );
}

export default DataTablePagination;
