import { FetchProspectsRequest, FetchProspectsResponse } from "./types";
import axios from "../axiosInstance";

export const fetchProspects = async (
  requestData: FetchProspectsRequest,
): Promise<FetchProspectsResponse> => {
  const response = await axios.get<FetchProspectsResponse>(
    "/api/private/stochastic-prospects",
    { params: requestData },
  );
  return response.data;
};
