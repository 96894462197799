import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { Customer } from "../../../../../../api/fetchCustomers/types";

export function createCustomersColumns(): ColumnDef<Customer>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "External ID",
      accessorKey: "external_id",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div>
          {/* Add action buttons or links here */}
          <button onClick={() => alert(`Customer ID: ${row.original.id}`)}>
            View
          </button>
        </div>
      ),
      enableSorting: false,
    },
  ];
}
